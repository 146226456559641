import services from '../services'
import { reactive } from 'vue'
import { useStore } from 'vuex'
import useNotificationToast from './useNotificationToast'

export default function () {
    
    const stateVendors = reactive({
        vendors: [],
        types: [],
        taxTypes: [
            {
                type: 'REAL',
                value: 'Real'
            },
            {
                type: 'PERCENT',
                value: 'Porcentagem'
            }
        ],
        loading: {
            vendors: false,
            types: false
        },
        currentPage: 1,
        perPage: 5,
        totalRecords: 0,
    })

    const store = useStore()
    const { error } = useNotificationToast()
    
    function loadVendors() {

        stateVendors.loading.vendors = true

        const params = {
            userFilter: stateVendors.userFilter,
            page: stateVendors.currentPage,
            perPage: stateVendors.perPage,
        }

        const token = store.getters.getToken

        services.vendors.getAll({ params, token })
            .then((response) => {
                stateVendors.vendors = response.data.data
                stateVendors.currentPage = response.data.meta.current_page
                stateVendors.totalRecords = response.data.meta.total
            })
            .catch(() => {
                error("Não foi possível carregar os fornecedores.")
            })
            .finally(() => {  stateVendors.loading.vendors = false })
            
    }

    function loadVendorsTypes() {

        stateVendors.loading.vendors = true


        const token = store.getters.getToken

        services.vendors.getTypes({ token })
            .then((response) => {
                stateVendors.types = response.data
            })
            .catch(() => {
                error("Não foi possível carregar os tipos dos fornecedores.")
            })
            .finally(() => {  stateVendors.loading.types = false })
            
    }

    function onPage(event) {
        stateVendors.currentPage = event.page + 1
        stateVendors.perPage = event.rows
        loadVendors()
    }

    function getInputNumberPrefixSymbol (transactionTax) {
        
        return  transactionTax.type === 'REAL' ? 'R$ ' : '% '
    }

    return {
        stateVendors,
        loadVendors,
        loadVendorsTypes,
        onPage,
        getInputNumberPrefixSymbol
    }
}
<template>
    <div v-if="state.loading.vendors">
        Carregando...
    </div>
    <div v-else class="p-fluid formgrid grid form">
        <div class="field col-12">
            <label>Nome</label>
            <InputText type="text" v-model="state.name" />
        </div>

        <div class="field col-12">
            <label>Tipo</label>
            <Dropdown class="mt-1" v-model="state.type" :options="stateVendors.types" />
        </div>

        <div class="field col-12 flex">

            <div class="w-full">
                <label>Tipo de Taxa Depósito</label>
                <Dropdown class="w-full mr-1" v-model="state.depositTax" optionLabel="value"
                    :options="stateVendors.taxTypes" />
            </div>


            <div class="w-full">
                <label>Valor de Taxa Depósito</label>
                <InputNumber class="ml-1" v-model="state.depositTaxValue" :prefix="depositTaxValueSymbol"
                    :maxFractionDigits="2" />
            </div>

        </div>

        <div class="field col-12 flex">

            <div class="w-full">
                <label>Tipo de Taxa Saque</label>
                <Dropdown class="w-full mr-1" v-model="state.withdrawTax" optionLabel="value"
                    :options="stateVendors.taxTypes" />
            </div>

            <div class="w-full">
                <label>Valor de Taxa Saque</label>
                <InputNumber class="ml-1" v-model="state.withdrawTaxValue" :prefix="withdrawTaxValueSymbol"
                    :maxFractionDigits="2" />
            </div>

        </div>

        <div class="field col-12">
            <label>Status</label>
            <Dropdown class="mt-1" v-model="state.active" :options="state.status" />
        </div>

        <div v-if="state.type" class="field col-12">

            <div v-if="state.type == 'SHIPAY' || state.type == 'SICOOB'" class="field">
                <label>Usuário</label>
                <InputText type="text" v-model="state.username"
                    @update:modelValue="sicoobUsernameToUppercase($event)" />
                <Message v-if="state.type == 'SICOOB'" severity="info"> Esse campo se refere ao nome do arquivo PFX.
                    Exemplo: Arquivo netuno.pfx, então esse campo deve ser NETUNO.
                </Message>
            </div>

            <div v-if="state.type == 'SICOOB'" class="field">
                <label>Senha</label>
                <Password v-model="state.password" :feedback="false" />
                <Message v-if="state.type == 'SICOOB'" severity="info"> Senha do arquivo .pfx
                </Message>
            </div>

            <!-- <div v-if="state.type == 'SICOOB'" class="field">
                <label>Merchant ID</label>
                <InputText type="text" v-model="state.merchantId" />
            </div> -->

            <div class="field">
                <label>Token</label>
                <InputText type="text" v-model="state.vendorToken" />
            </div>

            <div class="field">
                <label>Chave</label>
                <Password v-model="state.key" :feedback="false" />
                <Message v-if="state.type == 'SICOOB'" severity="info">Essa campo se refere a Chave pix do Sicoob
                    relacionada a conta desse Fornecedor
                </Message>
            </div>

            <div class="field">
                <Button :loading="state.loading.tryingToRegister" label="Registrar Fornecedor" icon="pi pi-check"
                    iconPos="right" @click="tryToregisterVendor" />
            </div>

        </div>

    </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Password from 'primevue/password'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Message from 'primevue/message'
import services from '../../services'
import { reactive, computed } from 'vue'
import { useStore } from 'vuex'
import useNotificationToast from '../../composables/useNotificationToast'
import useVendors from '../../composables/useVendors'

export default {

    components: { InputText, InputNumber, Password, Dropdown, Button, Message },

    setup() {

        const state = reactive({
            name: null,
            type: null,
            depositTax: null,
            depositTaxValue: null,
            withdrawTax: null,
            withdrawTaxValue: null,
            active: 'Ativado',
            status: ['Ativado', 'Desativado'],
            username: null,
            password: null,
            merchantId: null,
            vendorToken: null,
            key: null,
            loading: {
                tryingToRegister: false,
                vendors: false
            },
        })

        const depositTaxValueSymbol = computed(() => {

            if (state.depositTax) {
                return state.depositTax && state.depositTax.type === 'REAL' ? 'R$ ' : '% '
            } else {
                return null
            }

        })

        const withdrawTaxValueSymbol = computed(() => {

            if (state.withdrawTax) {
                return state.withdrawTax && state.withdrawTax.type === 'REAL' ? 'R$ ' : '% '
            } else {
                return null
            }

        })

        const store = useStore()
        const { success, error } = useNotificationToast()
        const { stateVendors, loadVendorsTypes } = useVendors()

        loadVendorsTypes()


        async function tryToregisterVendor() {

            state.loading.tryingToRegister = true

            if (
                !state.name ||
                !state.type ||
                !state.depositTax ||
                !state.depositTaxValue ||
                !state.withdrawTax ||
                !state.withdrawTaxValue) {

                state.loading.tryingToRegister = false
                return error('Verifique todos os campos!')

            }

            const token = store.getters.getToken

            await services.vendors.register({
                name: state.name,
                type: state.type,
                depositTaxType: state.depositTax.type,
                depositTaxValue: state.depositTaxValue,
                withdrawTaxType: state.withdrawTax.type,
                withdrawTaxValue: state.withdrawTaxValue,
                active: state.active === 'Ativado' ? true : false,
                username: state.username,
                password: state.password,
                merchantId: state.merchantId,
                vendorToken: state.vendorToken,
                key: state.key,
                token
            }).then(() => {
                success('Fornecedor registrado com sucesso!')
                state.name = null
                state.type = null
                state.depositTax = null
                state.depositTaxValue = null
                state.withdrawTax = null
                state.withdrawTaxValue = null
                state.active = state.status[0]
                state.password = null
                state.username = null
                state.merchantId = null
                state.vendorToken = null
                state.key = null
                state.loading.tryingToRegister = false
            })
                .catch((e) => {
                    const message = e.response.data.message
                    const toastMessage = message.charAt(0).toUpperCase() + message.slice(1)
                    error(toastMessage)
                })
                .finally(() => { state.loading.tryingToRegister = false })


            state.loading.tryingToRegister = false
        }

        function sicoobUsernameToUppercase(e) {

            if (this.state.type == 'SICOOB') {
                this.state.username = e.toUpperCase()
            }

        }

        return {
            state,
            stateVendors,
            depositTaxValueSymbol,
            withdrawTaxValueSymbol,
            Symbol,
            sicoobUsernameToUppercase,
            tryToregisterVendor
        }

    }
}

</script>

<style lang="scss" scoped>
.form {
    max-width: 500px;
    margin: 0 auto;
}
</style>